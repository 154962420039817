.rotation {
  margin-top: 10px;
  animation: 1s linear infinite rotation;
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
